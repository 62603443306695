<template>
  <div>
    <b-row class="pb-10">
      <b-col cols="12">
        <b-button class="float-left" variant="secondary-outline" style="border: 1px solid gray;opacity:0.58;" @click="$router.push('/dashboard')">
          <v-icon small style="">mdi-arrow-left</v-icon> Kembali
        </b-button>
          <p style="padding-left:42%;width:100%;margin:0 auto;font-weight: 600;font-size: 24px;line-height: 33px;">
            {{$route.query.id && listCMSKegiatan[0].kategori.parent_id == 1 ? 'Informasi' : 'Kegiatan'}}
          </p>
      </b-col>
    </b-row>
    <div class="row" v-for="(item,i) in listCMSKegiatan" :key="i">
      <div class="col-xxl-12 mb-10">
        <b-card
          no-body
          class="overflow-hidden card-custom-bg"
          style="max-width: 100%;"
        >
          <b-row no-gutters cla>
            <b-col md="3">
              <img v-if="item.path_image == null"
                src="data:image/png;base64,
                iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsG3u77s8fTCxsnn7O/f5OfFyczP0
                9bM0dO8wMPk6ezY3eDd4uXR1tnJzdBvAX/cAAACVElEQVR4nO3b23KDIBRA0ShGU0n0//+2KmO94gWZ8Zxmr7fmwWEHJsJUHw8AAAAAAAAAAAAAAAA
                AAAAAAAAAAAAAAAAAwO1MHHdn+L3rIoK6eshsNJ8kTaJI07fERPOO1Nc1vgQm2oiBTWJ+d8+CqV1heplLzMRNonED+4mg7L6p591FC+133/xCRNCtd3
                nL9BlxWP++MOaXFdEXFjZ7r8D9l45C8y6aG0cWtP/SUGhs2d8dA/ZfGgrzYX+TVqcTNRRO9l+fS5eSYzQs85psUcuzk6igcLoHPz2J8gvzWaH/JLS+9
                5RfOD8o1p5CU5R7l5LkfKEp0mQ1UX7hsVXqDpRrifILD/3S9CfmlUQFhQfuFu0STTyJ8gsP3PH7GVxN1FC4t2sbBy4TNRTu7LyHJbqaqKFw+/Q0ncFloo7Cj
                RPwMnCWqKXQZ75El4nKC9dmcJaou9AXOE5UXbi+RGeJygrz8Uf+GewSn9uXuplnWDZJ7d8f24F/s6iq0LYf9olbS3Q8i5oKrRu4S9ybwaQ/aCkqtP3I28QDge
                oK7TBya/aXqL5COx67PTCD2grtdOwH+pQV2r0a7YVBgZoKwwIVFQYG6ikMDVRTGByopjD8ATcKb0UhhRTe77sKs2DV7FKSjId18TUEBYVyLhUThWfILHTDqmI85/2
                RWWjcE/bhP6OD7maT3h20MHsA47JC3PsW0wcwLhv9t0OOPOIkCn21y2bXXwlyylxiYMPk1SuCSmpfK8bNQvIrpAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwNX4B
                CbAju9/X67UAAAAASUVORK5CYII="
                class="round-image-dash" style="margin:6px;"
              >
              <img :src="fileURL + '/' + item.path_image" alt="" style="width:250px;">
            </b-col>
            <b-col md="9">
              <b-card-body class="pt-5">
                <b-badge href="#" style="background-color:#DDCEF0;" class="width-badgee"
                  >{{ item.kategori.name}}</b-badge
                >
                <h2 class="pt-5">{{item.title}}</h2>
                <b-card-text>
                  {{ item.content}}
                </b-card-text>
                <p class="p-custom">{{ item.tanggal_konten}}</p>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>     
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "builder",
  components: {},
  data() {
    return {
      tabIndex: 0,
      listCMSKegiatan: null,
      listKategori: null,
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    if(this.$route.query.id) this.getKontenDetail(this.$route.query.id)
    else this.getKonten()
  },
  methods: {
    getKonten(){
      this.$store
      .dispatch("getCMSByParent", 2)
      .then((res) => {
        this.listCMSKegiatan = res.data
      })
      this.getKategori()
    },
    getKategori(){
      this.$store
      .dispatch("listKategoriPilihan")
      .then((res) => {
        this.listKategori = res.data
      })
    },
    getKontenDetail(id){
      this.$store
      .dispatch("getCMSDetail", id)
      .then((res) => {
        this.listCMSKegiatan = [res.data]
      })
    }
  }
};
</script>
